import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Image } from "lib/imgproxy";

/**
 * Props for `GrowthSlice`.
 */
export type GrowthSliceProps = SliceComponentProps<Content.GrowthSliceSlice>;

/**
 * Component for "GrowthSlice" Slices.
 */
const GrowthSlice = ({ slice }: GrowthSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="mt-[70px] flex flex-col items-center gap-[60px] lg:mt-[100px]"
    >
        <div className="typography-h2 lg:typography-h1 font-semibold text-blue-grey-900">
            {slice.primary.title}
        </div>
        <div className="flex flex-col gap-12 lg:flex-row">
            {slice.primary.reasons.map((r) => (
                <div
                    key={r.title}
                    className="flex w-[260px] flex-col items-center gap-8"
                >
                    <div className="relative aspect-[0.929] h-[180px] w-fit">
                        <Image
                            alt={r.icon.alt ?? ""}
                            src={r.icon.url ?? ""}
                            unoptimized
                            layout="fill"
                            objectFit="contain"
                            sizes="280px"
                        />
                    </div>
                    <div className="flex flex-col gap-5 text-center">
                        <h3 className="typography-h3 font-medium text-blue-grey-900">
                            {r.title}
                        </h3>
                        <div className="typography-main text-blue-grey">
                            {r.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </section>
);

export default GrowthSlice;
